import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { catchError, tap, map } from 'rxjs/operators';
import { Observable, BehaviorSubject, of, throwError, observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class BonusesService {

  // public user = JSON.parse(!this.platform.is('android') && !this.platform.is('ios') ? environment.storageWeb.getItem('USER') : environment.storageMobile.getItem('USER'));

  // public httpOptions = {
  //   headers: new HttpHeaders({
  //     'x-access-token': this.user.accessToken,
  //   }),
  // };

  constructor(
    private httpClient: HttpClient,
    private platform: Platform,
  ) { }


  GetBonuses(): Observable<any> {
    const user = JSON.parse(!this.platform.is('android') && !this.platform.is('ios') ? environment.storageWeb.getItem('USER') : environment.storageMobile.getItem('USER'));
    const doc = user.taxvat;
    return this.httpClient
      .get(
        `${environment.SERVER_ADDRESS}bonuse/document/${doc}`,
        // `${environment.SERVER_ADDRESS}bonuse/document/10721814794`,
      )
      .pipe(tap(async (res: any) => { }));
  }

  GetBonusesAllSearch(dados): Observable<any> {
    const user = JSON.parse(!this.platform.is('android') && !this.platform.is('ios') ? environment.storageWeb.getItem('USER') : environment.storageMobile.getItem('USER'));
    let Data = {
      documento: user.taxvat,
      // documento: '10721814794',
      status: dados.status,
      dataInicio: dados.dataInicio,
      dataFim: dados.dataFim
    }
    return this.httpClient
      .post(
        `${environment.SERVER_ADDRESS}bonuse/document/search`,
        Data,
      )
      .pipe(tap(async (res: any) => { }));
  }

}
